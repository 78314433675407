@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.my_80 {
  margin: 80px 0;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.world-map svg {
  display: inline-block;
  vertical-align: middle;
}

.world-map path {
  fill: #8791c9;
}

/* .heading-h2 {
  position: relative;
  padding-bottom: 25px;
}

.heading-h2::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 2px;
  background-color: #283895;
  bottom: 12px;
  right: 49.5%;
  transform: translate(50%, 50%);
} */

.infaVideo:hover .pauseVideo {
  display: flex !important;
}

.wrapper {
  background-color: black;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}

.map-locations {
  width: 32px;
  height: 32px;
  background-image: none;
  background-position: 0 0;
  background-size: cover;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 53%;
  bottom: auto;
  left: 66.2%;
  right: auto;
}

.map-locations.russia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 31%;
  left: 77%;
}

.map-locations.india {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 58.5%;
  left: 66.6%;
}
.map-locations.pakistan {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 54.5%;
  left: 64.6%;
}

.map-locations.europe {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 40.5%;
  left: 49.6%;
}

.map-locations.bangladesh {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 57.5%;
  left: 72.6%;
}

.map-locations.vietnam {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 61.5%;
  left: 75.6%;
}

.map-locations.westBengal {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 55.9%;
  left: 70.7%;
}

.map-locations.bahrain {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 55%;
  left: 60%;
}

.map-locations.uae {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 58%;
  left: 61.5%;
}

.map-locations.srilanka {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 63.5%;
  left: 68.3%;
}

.map-locations.maldives {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 66%;
  left: 66%;
}

.map-locations.australia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 78%;
  left: 84%;
}

.map-locations.new-zealand {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 85%;
  left: 94%;
}

.map-locations.mauritius {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 75%;
  left: 61.5%;
}

.map-locations.south-africa {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 80%;
  left: 53%;
}

.map-locations.botswana {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 76%;
  left: 52%;
}

.map-locations.zambia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 72.5%;
  left: 53%;
}

.map-locations.israel {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 52.5%;
  left: 55.5%;
}

.map-locations.lebanon {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 51%;
  left: 55%;
}

.map-locations.singapore {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 65.5%;
  left: 74%;
}

.map-locations.malaysia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 65%;
  left: 78%;
}

.map-locations.taiwan {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 56.5%;
  left: 79.5%;
}

.map-locations.hong-kong {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 56%;
  left: 78%;
}

.map-locations.south-korea {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 50%;
  left: 81.5%;
}

.map-locations.japan {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 48%;
  left: 85%;
}

.map-locations.china {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 52%;
  left: 74%;
}

.map-locations.thailand {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 60%;
  left: 74%;
}

.map-locations.canada {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 35%;
  left: 14%;
}

.map-locations.usa {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 48%;
  left: 18%;
}

.map-locations.mexico {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 56%;
  left: 17%;
}

.map-locations.st-martin {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 58.5%;
  left: 27.5%;
}

.map-locations.peru {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 70%;
  left: 24.5%;
}

.map-locations.iceland {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 30%;
  left: 40.5%;
}

.map-locations.finland {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 28%;
  left: 53%;
}

.map-locations.sweden {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 29%;
  left: 51%;
}

.map-locations.norway {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 33%;
  left: 48%;
}

.map-locations.slovakia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 42.5%;
  left: 51%;
}

.map-locations.bratislava {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 43.5%;
  left: 50%;
}

.map-locations.poland {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 40%;
  left: 51%;
}

.map-locations.lithuania {
  z-index: 7;
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 38.5%;
  left: 52.5%;
}

.map-locations.latvia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 36%;
  left: 53%;
}

.map-locations.denmark {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 40%;
  left: 48%;
}

.map-locations.uk {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 40%;
  left: 45.5%;
}

.map-locations.ireland {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 40%;
  left: 43.5%;
}

.map-locations.northern-ireland {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 38%;
  left: 44%;
}

.map-locations.belguim {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 41.7%;
  left: 47%;
}

.map-locations.france {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 44%;
  left: 46%;
}

.map-locations.germany {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 42%;
  left: 49%;
}

.map-locations.monaco {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 46%;
  left: 47%;
}

.map-locations.italy {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 46%;
  left: 49%;
}

.map-locations.malta {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 50%;
  left: 50%;
}

.map-locations.switzerland {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 44%;
  left: 48%;
}

.map-locations.czech-republic {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 41%;
  left: 50%;
}

.map-locations.romania {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 40%;
  left: 54%;
}

.map-locations.austria {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 44%;
  left: 52%;
}

.map-locations.greece {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 48%;
  left: 52%;
}

.map-locations.hungry {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 46%;
  left: 53%;
}

.map-locations.turkey {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 49%;
  left: 55%;
}

.map-locations.cyprus {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 50.5%;
  left: 53%;
}

.map-locations.armenia {
  background-image: url(https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63d77f7632dbbf5efa303ae0_fa-solid_map-pin.svg);
  top: 48%;
  left: 58%;
}

@media screen and (max-width: 991px) {
  .map-locations.russia {
    width: 20px;
    height: 20px;
    top: 29%;
    left: 73%;
  }

  .map-locations.india,
  .map-locations.bahrain,
  .map-locations.uae,
  .map-locations.srilanka,
  .map-locations.maldives,
  .map-locations.australia,
  .map-locations.new-zealand,
  .map-locations.mauritius,
  .map-locations.south-africa,
  .map-locations.botswana,
  .map-locations.zambia,
  .map-locations.israel,
  .map-locations.pakistan,
  .map-locations.westBengal,
  .map-locations.vietnam,
  .map-locations.europe,
  .map-locations.bangladesh,
  .map-locations.turkey,
  .map-locations.lebanon {
    width: 20px;
    height: 20px;
  }

  .map-locations.singapore {
    width: 20px;
    height: 20px;
    background-size: cover;
  }

  .map-locations.malaysia,
  .map-locations.taiwan,
  .map-locations.hong-kong,
  .map-locations.south-korea,
  .map-locations.japan,
  .map-locations.china,
  .map-locations.thailand,
  .map-locations.canada,
  .map-locations.usa,
  .map-locations.mexico,
  .map-locations.st-martin,
  .map-locations.peru,
  .map-locations.iceland,
  .map-locations.finland,
  .map-locations.sweden,
  .map-locations.norway,
  .map-locations.slovakia,
  .map-locations.bratislava,
  .map-locations.poland,
  .map-locations.lithuania,
  .map-locations.latvia,
  .map-locations.denmark,
  .map-locations.uk,
  .map-locations.ireland,
  .map-locations.northern-ireland,
  .map-locations.belguim,
  .map-locations.france,
  .map-locations.germany,
  .map-locations.monaco,
  .map-locations.italy,
  .map-locations.malta,
  .map-locations.switzerland,
  .map-locations.czech-republic,
  .map-locations.romania,
  .map-locations.austria,
  .map-locations.greece,
  .map-locations.hungry,
  .map-locations.turkey,
  .map-locations.cyprus,
  .map-locations.pakistan,
  .map-locations.westBengal,
  .map-locations.vietnam,
  .map-locations.europe,
  .map-locations.bangladesh,
  .map-locations.armenia {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .map-locations.russia,
  .map-locations.india,
  .map-locations.bahrain,
  .map-locations.uae,
  .map-locations.srilanka,
  .map-locations.maldives,
  .map-locations.australia,
  .map-locations.new-zealand,
  .map-locations.mauritius,
  .map-locations.south-africa,
  .map-locations.botswana,
  .map-locations.zambia,
  .map-locations.israel,
  .map-locations.lebanon,
  .map-locations.singapore,
  .map-locations.malaysia,
  .map-locations.taiwan,
  .map-locations.hong-kong,
  .map-locations.south-korea,
  .map-locations.japan,
  .map-locations.china,
  .map-locations.thailand,
  .map-locations.canada,
  .map-locations.usa,
  .map-locations.mexico,
  .map-locations.st-martin,
  .map-locations.peru,
  .map-locations.iceland,
  .map-locations.finland,
  .map-locations.sweden,
  .map-locations.norway,
  .map-locations.slovakia,
  .map-locations.bratislava,
  .map-locations.poland,
  .map-locations.lithuania,
  .map-locations.latvia,
  .map-locations.denmark,
  .map-locations.uk,
  .map-locations.ireland,
  .map-locations.northern-ireland,
  .map-locations.pakistan,
  .map-locations.westBengal,
  .map-locations.vietnam,
  .map-locations.europe,
  .map-locations.bangladesh,
  .map-locations.turkey,
  .map-locations.belguim {
    width: 16px;
    height: 16px;
  }

  .map-locations.france {
    width: 16px;
    height: 16px;
    object-fit: fill;
  }

  .map-locations.germany,
  .map-locations.monaco,
  .map-locations.italy,
  .map-locations.malta,
  .map-locations.switzerland,
  .map-locations.czech-republic,
  .map-locations.romania,
  .map-locations.austria,
  .map-locations.greece,
  .map-locations.hungry,
  .map-locations.turkey,
  .map-locations.cyprus,
  .map-locations.pakistan,
  .map-locations.westBengal,
  .map-locations.vietnam,
  .map-locations.europe,
  .map-locations.bangladesh,
  .map-locations.turkey,
  .map-locations.armenia {
    width: 16px;
    height: 16px;
  }

  .map-main-div {
    width: 100% !important;
  }
}

.banner-image-wrapper {
  /* height: 879px; */
  width: 100%;
  /* height: calc(100% - 107px); */
}

.wrapper {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.banner-image {
  /* height: auto; */
  width: 100%;
  max-height: 700px;
}

/* @media screen and (max-width: 1192px) {
  .banner-image-wrapper {
    height: auto;
  }
} */

a {
  text-decoration: none;
}

.header {
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
  max-width: inherit;
  background: #fff;
  /* transition: background 0.5s; */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.img-center {
  display: block;
  height: 50px;
}
.navbar-header {
  /* width: 78%; */
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  /* width: 78%; */
  /* border-bottom: 1px solid rgb(131, 129, 129); */
  /* margin-left: auto;
  margin-right: auto; */
}

.nav-item a {
  color: #192257;
  font-size: 14px;
  /* transition: all 500ms ease; */
}

.nav-colored {
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.nav-colored .nav-item a {
  color: #192257;
}

.nav-item:hover a {
  color: black;
  /* font-size: 14px; */
  font-weight: bold;
}

.menuNavItem {
  display: none;
}

.activeNav a {
  color: black !important;
  font-weight: bold;
}

/* @media screen and (max-width: 1440px) {
  .navbar-header {
    width: 90%;
  }
} */

@media screen and (max-width: 1192px) {
  /* .navbar-header {
    width: 80%;
  } */
}

@media screen and (max-width: 992px) {
  .nav-item {
    display: none;
  }
  .menuNavItem {
    display: block;
  }
  .navbar {
    justify-content: flex-end;
  }
}

.MuiDrawer-paper {
  width: 60% !important;
}

@media (max-width: 576px) {
  .MuiDrawer-paper {
    width: 100% !important;
  }
}

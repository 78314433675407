.footer-ul {
  list-style-type: none;
}

footer {
  background-color: #192257;
  padding: 60px 0 30px 0;
}

.footer-ul li {
  margin: 6px 0;
}

.footer-ul li a {
  color: #d2d2d2;
  font-size: 14px;
}

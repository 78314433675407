.background {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.background::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.background {
  /* background: linear-gradient(#e66465, #9198e5); */
  /* background-color: #2b4162;
  background-image: linear-gradient(315deg, #2b4162 0%, #12100e 74%); */
  background-color: #7a7adb;
  background-image: linear-gradient(315deg, #7a7adb 0%, #170e13 74%);
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  /* width: 100%;
  height: 90vh; */
  background-attachment: fixed;
}

.scrollText {
  font-size: 1.5rem;
}

.festCard {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: max-content;
  font-size: 1.25rem;
  /* width: 30%; */
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
}

.festCardImg {
  height: 350px;
}

.festCardText {
  padding: 10px 0;
}

.parallax {
  margin-right: 15%;
}

.sticky {
  margin-left: 15%;
  background-color: #ffff;
  /* color: #fff; */
  padding: 50px;
}

.blue {
  background-color: #5b65ad;
  color: white;
}

.purple {
  background-color: #9d65d0;
}

@media screen and (max-width: 899px) {
  .festCard {
    width: 100%;
  }
  .festCardImg {
    width: 100%;
  }

  .parallax {
    margin-right: 0;
  }

  .sticky {
    margin-left: 0;
  }
}

/* @media (max-width: 750px) {
  .festCard {
    width: 40%;
  }

  .parallax {
    margin-right: 1.5rem;
  }

  .sticky {
    margin-left: 1.5rem;
  }
} */

/* .products-slider .slick-slider .slick-list {
  height: 433px;
} */

.products-slider .slick-prev:before,
.slick-next:before {
  color: black;
  font-weight: 700;
  background: #0000001c;
  /* padding: 20px; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: normal;
  font-family: "Font Awesome 6 Free";
  width: 35px;
  height: 35px;
}

.products-slider .slick-next:before {
  content: "\f105";
}

.products-slider .slick-prev:before {
  content: "\f104";
}

.products-slider .slick-prev {
  top: -39px;
  right: 88px;
  left: auto;
}

.products-slider .slick-next {
  right: 43px;
  top: -39px;
}

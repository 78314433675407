.product-card {
  cursor: pointer;
}
.product-img-div {
  background-color: #f5f5f5;
  transition: all 0.3s linear;
  position: relative;
  border: 1px solid #f5f5f5;
  overflow: hidden;
}

.product-img-wrapper {
  /* width: 310px; */
  /* height: 310px; */
  padding: 10px;
  transition: all 0.3s linear;
  aspect-ratio: 1 / 1;
}

.product-img-wrapper:hover {
  scale: 1.05;
}

.productImg {
  /* width: 100%;
  height: 100%; */
  height: -webkit-fill-available;
  margin: auto;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.product-info {
  padding: 20px 0;
  text-align: center;
}

.product-info .description {
  margin: 8px 0;
  color: rgb(96, 105, 123);
  font-size: 14px;
}

.product-img-div .read-more-product {
  background-color: #192257;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  position: absolute;
  opacity: 0;
  width: 100%;
  /* bottom: -30px; */

  /* visibility: hidden; */
}

.product-img-div:hover {
  border-color: #192257;
}

.product-img-div:hover .read-more-product {
  opacity: 1;
  transform: translateY(0%);
  bottom: 0;
  animation: mymove 500ms linear;
}

@keyframes mymove {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
